// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ModulesModal_ModulesModal__h\\+jcN {
  min-width: 80%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: calc(100vh - 1rem);
  overflow: auto;
}
.ModulesModal_ModulesModal__Body__lJ4Ee {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  padding: calc(24px + 0.4rem);
}
.ModulesModal_ModulesModal__Body__lJ4Ee .ModulesModal_closeButton__7BO4d {
  position: absolute;
  top: 0.2rem;
  right: 0.2rem;
}
.ModulesModal_ModulesModal__Body__lJ4Ee nav {
  width: calc(100% - 48px - 0.8rem);
}`, "",{"version":3,"sources":["webpack://./src/components/ModulesModal.module.scss"],"names":[],"mappings":"AACA;EACI,cAAA;EACA,SAAA;EACA,QAAA;EACA,gCAAA;EACA,0BAAA;EACA,cAAA;AAAJ;AAEI;EACI,aAAA;EACA,sBAAA;EACA,YAAA;EACA,kBAAA;EACA,4BAAA;AAAR;AAEQ;EACI,kBAAA;EACA,WAAA;EACA,aAAA;AAAZ;AAGQ;EACI,iCAAA;AADZ","sourcesContent":["@use \"/home/runner/_work/gmc-dc-pbui-fe/gmc-dc-pbui-fe/src/styles/global-variables.scss\" as *;\n.ModulesModal {\n    min-width: 80%;\n    left: 50%;\n    top: 50%;\n    transform: translate(-50%, -50%);\n    height: calc(100vh - 1rem);\n    overflow: auto;\n\n    &__Body {\n        display: flex;\n        flex-direction: column;\n        height: 100%;\n        position: relative;\n        padding: calc(24px + 0.4rem);\n\n        .closeButton {\n            position: absolute;\n            top: 0.2rem;\n            right: 0.2rem;\n        }\n\n        nav {\n            width: calc(100% - 48px - 0.8rem);\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ModulesModal": `ModulesModal_ModulesModal__h+jcN`,
	"ModulesModal__Body": `ModulesModal_ModulesModal__Body__lJ4Ee`,
	"closeButton": `ModulesModal_closeButton__7BO4d`
};
export default ___CSS_LOADER_EXPORT___;

import React, { useMemo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import Button from 'components/Button/Button';
import LoadingSpinner from 'components/LoadingSpinner';
import NavBar from 'components/NavBar/NavBar';
import Tab from 'components/Tab';
import { buildCombinations } from 'pages/Combinations/utils';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  selectModulesLoadingStatus,
  selectModulesError,
  selectModulesInfo,
  selectModulesSections,
  actions,
  selectTextCollection,
} from 'store/modules/slice';
import { ModulesBundleModule, ModulesTypeList } from 'store/modules/types';
import BundleList from './components/BundleList';
import BundlesNavigation from './components/BundlesNavigation';
import { BundlesContext } from './context/bundles';
import { convertBundleWithSelectionToBundle, validateSelection } from './hooks/helpers';
import useBundles from './hooks/useBundles';
import styles from './Modules.module.scss';

export const moduleTypeTestId = 'moduleTypeTestId';

const getParams = (
  modal: { jobZeroId: string; moduleBundleId: string } | undefined, queryParams: URLSearchParams,
): [string, string] => {
  if (modal) {
    return [modal.jobZeroId, modal.moduleBundleId];
  } else {
    return [queryParams.get('jobzero') ?? '', queryParams.get('bundle') ?? ''];
  }
};

const validateParams = (jobZeroId: string, moduleBundleId: string): boolean => !!(jobZeroId && moduleBundleId);

type Props = {
  modal?: {
    jobZeroId: string;
    moduleBundleId: string;
    onFormSubmit: () => void;
    isNoMoreToAdd: boolean;
  };
};

const Modules: React.FC<Props> = ({ modal }) => {
  const navigate = useNavigate();
  const [queryParams] = useSearchParams();
  const [jobZeroId, moduleBundleId] = getParams(modal, queryParams);

  const dispatchToRedux = useAppDispatch();
  const dataInfo = useAppSelector(selectModulesInfo);
  const dataSections = useAppSelector(selectModulesSections);
  const dataLoading = useAppSelector(selectModulesLoadingStatus);
  const dataError = useAppSelector(selectModulesError);
  const textCollections = useSelector(selectTextCollection);

  const [isSubmitAttempt, setIsSubmitAttempt] = useState(false);

  const {
    bundles,
    bundlesIsActive,
    bundlesDispatch,
    selectedCount,
    selectionIsValid,
    activeBundleIndex,
    setActiveBundleIndex,
    setNextBundleActive,
    setPrevBundleActive,
  } = useBundles();

  const bundlesContext = useMemo(() => ({
    bundles,
    bundlesDispatch,
  }), [bundles, bundlesDispatch]);

  useEffect(() => {
    const sameParams = dataInfo?.jobZero.id === jobZeroId && dataInfo?.moduleBundle.id === moduleBundleId;
    if (!sameParams && validateParams(jobZeroId, moduleBundleId)) {
      dispatchToRedux(actions.getModulesRequest({
        jobZeroId,
        moduleBundleId,
      }));
    }
  }, [jobZeroId, moduleBundleId]); // dataInfo intentionally skipped from the deps

  const resultedCombinationsNumber = useMemo(
    () => (dataSections && bundles.length) ? buildCombinations(bundles, dataSections).length : 0,
    [bundles, dataSections],
  );

  const onBrandClick = (): void => {
    dispatchToRedux(actions.resetModules());
  };

  const onFormSubmitHandler = (evt: React.FormEvent): void => {
    evt.preventDefault();

    const modules = bundles.reduce((acc, curr) => {
      return [...acc, ...curr.modules];
    }, [] as ModulesBundleModule[]);

    const isPromotionals = Boolean(
      modules.filter(
        module => module.classification === ModulesTypeList.PROMOTIONAL_MESSAGE && module.selected,
      ).length,
    );

    // Currently we have to postpon this solutionb but we need to test this story.
    if (isPromotionals
        && window.FEATURE_TEXT_COLLECTION_MODAL
        && textCollections.length !== 1
    ) {
      dispatchToRedux(actions.openTextCollectionModal(true));
    } else {
      setIsSubmitAttempt(true);
      if (!validateSelection(bundles)) {
        return;
      }

      dispatchToRedux(actions.updateModulesBundles(bundles.map(convertBundleWithSelectionToBundle)));
      if (modal) {
        // in case this button is clicked from the modal dialog of Preview page
        modal.onFormSubmit();
      } else {
        navigate('/combinations');
      }
    }

  };

  const activeBundle = bundles[activeBundleIndex];
  const isInvalidToRender = !validateParams(jobZeroId, moduleBundleId) || dataError;

  if (isInvalidToRender && modal) {
    return (
      <div className='text-danger'>
        {`Invalid parameters ${jobZeroId}/${moduleBundleId}`}
      </div>
    );
  }

  if (isInvalidToRender) {
    return <Navigate to="/" />;
  }

  if (dataLoading || !dataInfo || !bundlesIsActive || !dataSections || !activeBundle) {
    return (
      <div
        className="container d-flex align-items-center justify-content-center"
        style={{ height: '100vh' }}
      >
        <LoadingSpinner />
      </div>
    );
  }

  const { jobZero } = dataInfo;

  if (modal?.isNoMoreToAdd) {
    return (
      <>
        <NavBar textContent={jobZero.name} />
        <main
          className={`container-fluid mx-lg-5 px-3 ${styles.contentContainer}`}
        >
          <h4 className="p-0 m-0 mb-1" style={{ width: 'auto' }}>
            Select the modules you want to use
          </h4>
          <p className="p-0 m-0 pb-3 border-bottom">
            {
              window.FEATURE_TEXT_COLLECTION_MODAL
                ? 'Select at least one module. Indication(s) and SSI content will be added automatically.'
                : 'Select at least one module.'
            }
          </p>
          <div
            className="container d-flex align-items-center justify-content-center"
            style={{ flex: '1 1 0', fontWeight: 500 }}
          >
            All possible combinations have been added
          </div>
        </main>
      </>
    );
  }

  const activeBundleLacksPlaceholder = dataSections.findIndex(
    i => i.placeholder && i.bundleIndexes.includes(activeBundleIndex),
  ) < 0;

  return (
    <>
      <NavBar
        textContent={jobZero.name}
        onBrandClick={onBrandClick}
      />
      <main className={`container-fluid mx-lg-5 px-3 ${styles.contentContainer}`} >
        <h4 className="p-0 m-0 mb-1" style={{ width: 'auto' }}>
          Select the modules you want to use
        </h4>
        <p className="p-0 m-0 pb-3 border-bottom">
          {
            window.FEATURE_TEXT_COLLECTION_MODAL
              ? 'Select at least one module. Indication(s) and SSI content will be added automatically.'
              : 'Select at least one module.'
          }
        </p>

        <form className="d-flex pt-4" onSubmit={onFormSubmitHandler}>
          <div className={`me-5 flex-shrink-0 ${styles.tabsContainer}`}>

            <h6 className="mb-3 fw-bold">Module Types</h6>
            <ul className={`nav nav-pills flex-column flex-nowrap mb-4 ${styles.tabs}`}>
              {bundles.map((bundle, index) =>
                (<Tab
                  key={bundle.index}
                  count={bundle.selectedCount}
                  index={index}
                  isActive={index === activeBundleIndex}
                  label={bundle.type}
                  testId={moduleTypeTestId}
                  onClick={(): void => setActiveBundleIndex(index)}
                />),
              )}
            </ul>

            {isSubmitAttempt && !selectionIsValid && (
              <div className={styles.selectAtLeastOneModule}>
                Select at least one module to create combinations.
              </div>
            )}
            <Button
              type="submit"
              isDisabled={false}
              label={selectionIsValid ? `Add Combinations (${resultedCombinationsNumber})` : 'Add Combinations' }
              styleOverride={{ width: '100%' }}
              className="mb-3"
            />

            {selectionIsValid && (
              <div className={styles.possibleCombinationsMessage}>
                {`${resultedCombinationsNumber} combination(s) will be added. You will have the option to review
                the content in the next step.`}
              </div>
            )}

            {!selectionIsValid && selectedCount > 0 && (
              <div className={styles.selectRemainingModulesMessage}>
                Select modules for the remaining module types to create combinations.
              </div>
            )}

          </div>
          <div className={`flex-grow-1 ${styles.modulesContainer}`}>

            {activeBundleLacksPlaceholder && (
              <div className='text-danger pt-2 pb-4 px-2'>no placeholder in the template</div>
            )}

            <BundlesContext.Provider value={bundlesContext}>
              <BundleList bundleIndex={activeBundleIndex} />
            </BundlesContext.Provider>

            <BundlesNavigation
              onPrev={setPrevBundleActive}
              onNext={setNextBundleActive}
            />

          </div>
        </form>
      </main>
    </>
  );
};

export default Modules;

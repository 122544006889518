// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CombinationNamesInformModal_CombinationNamesInformModal__v3rLo {
  left: calc(50% - 228.5px);
  max-width: 457px;
}
@media only screen and (max-width: 500px) {
  .CombinationNamesInformModal_CombinationNamesInformModal__v3rLo {
    max-width: 100%;
    left: 0;
    top: 0;
  }
}
.CombinationNamesInformModal_CombinationNamesInformModal__message__dha-Z {
  margin-left: 32px;
}
.CombinationNamesInformModal_CombinationNamesInformModal__header_icon__d3Sk\\+ {
  display: inline-block;
}
.CombinationNamesInformModal_CombinationNamesInformModal__header_title__uOD5S {
  display: flex;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/pages/Combinations/components/CombinationNamesInformModal/CombinationNamesInformModal.module.scss"],"names":[],"mappings":"AAGA;EACI,yBAAA;EACA,gBAJ+B;AAEnC;AAII;EAJJ;IAKQ,eAAA;IACA,OAAA;IACA,MAAA;EADN;AACF;AAGI;EACI,iBAAA;AADR;AAKQ;EACI,qBAAA;AAHZ;AAMQ;EACI,aAAA;EACA,mBAAA;AAJZ","sourcesContent":["@use \"/home/runner/_work/gmc-dc-pbui-fe/gmc-dc-pbui-fe/src/styles/global-variables.scss\" as *;\n$combinationNamesInformModalWidth: 457px;\n\n.CombinationNamesInformModal {\n    left: calc(50% - $combinationNamesInformModalWidth / 2);\n    max-width: $combinationNamesInformModalWidth;\n\n    @media only screen and (max-width: 500px) {\n        max-width: 100%;\n        left: 0;\n        top: 0;\n    }\n\n    &__message {\n        margin-left: 32px;\n    }\n\n    &__header {\n        &_icon {\n            display: inline-block;\n        }\n\n        &_title {\n            display: flex;\n            align-items: center;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"CombinationNamesInformModal": `CombinationNamesInformModal_CombinationNamesInformModal__v3rLo`,
	"CombinationNamesInformModal__message": `CombinationNamesInformModal_CombinationNamesInformModal__message__dha-Z`,
	"CombinationNamesInformModal__header_icon": `CombinationNamesInformModal_CombinationNamesInformModal__header_icon__d3Sk+`,
	"CombinationNamesInformModal__header_title": `CombinationNamesInformModal_CombinationNamesInformModal__header_title__uOD5S`
};
export default ___CSS_LOADER_EXPORT___;

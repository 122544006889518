// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RecipeModal_RecipeModal__oIvse {
  left: 30%;
  width: 40%;
}

.RecipeModal_modalHeader__YTUGs {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
}

.RecipeModal_modalBody__H5zfN {
  padding: 16px 16px 0;
  border-top: 1px solid #dee2e6;
  border-bottom: 1px solid #dee2e6;
}

.RecipeModal_modalFooter__cxO7x {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 16px;
}

.RecipeModal_alert__Wq53O {
  background: #FFF3CD;
  border: 1px solid #FFECB5;
  border-radius: 4px;
  margin-bottom: 16px;
  padding: 16px;
}

.RecipeModal_label__GCQ9U {
  margin-bottom: 8px;
}

.RecipeModal_tip__MJ7Et {
  margin-top: -10px;
  margin-bottom: 16px;
}

.RecipeModal_error__OHAgc {
  margin-top: -10px;
  margin-bottom: 16px;
  color: #DC3545;
}

.RecipeModal_networkError__pIZH6 {
  margin-bottom: 16px;
  margin-right: 8px;
  color: #DC3545;
}

.RecipeModal_ownerContainer__G1TAo {
  width: 70%;
}`, "",{"version":3,"sources":["webpack://./src/pages/Combinations/components/RecipeModal.module.scss"],"names":[],"mappings":"AACA;EACE,SAAA;EACA,UAAA;AAAF;;AAGA;EACE,aAAA;EACA,cAAA;EACA,mBAAA;EACA,8BAAA;EACA,aAAA;AAAF;;AAGA;EACE,oBAAA;EACA,6BAAA;EACA,gCAAA;AAAF;;AAGA;EACE,aAAA;EACA,cAAA;EACA,eAAA;EACA,mBAAA;EACA,yBAAA;EACA,aAAA;AAAF;;AAGA;EACE,mBAAA;EACA,yBAAA;EACA,kBAAA;EACA,mBAAA;EACA,aAAA;AAAF;;AAGA;EACE,kBAAA;AAAF;;AAGA;EACE,iBAAA;EACA,mBAAA;AAAF;;AAGA;EACE,iBAAA;EACA,mBAAA;EACA,cAAA;AAAF;;AAGA;EACE,mBAAA;EACA,iBAAA;EACA,cAAA;AAAF;;AAGA;EACE,UAAA;AAAF","sourcesContent":["@use \"/home/runner/_work/gmc-dc-pbui-fe/gmc-dc-pbui-fe/src/styles/global-variables.scss\" as *;\n.RecipeModal {\n  left: 30%;\n  width: 40%;\n}\n\n.modalHeader {\n  display: flex;\n  flex-shrink: 0;\n  align-items: center;\n  justify-content: space-between;\n  padding: 16px;\n}\n\n.modalBody {\n  padding: 16px 16px 0;\n  border-top: 1px solid $borderColor;\n  border-bottom: 1px solid $borderColor;\n}\n\n.modalFooter {\n  display: flex;\n  flex-shrink: 0;\n  flex-wrap: wrap;\n  align-items: center;\n  justify-content: flex-end;\n  padding: 16px;\n}\n\n.alert {\n  background: #FFF3CD;\n  border: 1px solid #FFECB5;\n  border-radius: 4px;\n  margin-bottom: 16px;\n  padding: 16px;\n}\n\n.label {\n  margin-bottom: 8px;\n}\n\n.tip {\n  margin-top: -10px;\n  margin-bottom: 16px;\n}\n\n.error {\n  margin-top: -10px;\n  margin-bottom: 16px;\n  color: #DC3545;\n}\n\n.networkError {\n  margin-bottom: 16px;\n  margin-right: 8px;\n  color: #DC3545;\n}\n\n.ownerContainer {\n  width: 70%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"RecipeModal": `RecipeModal_RecipeModal__oIvse`,
	"modalHeader": `RecipeModal_modalHeader__YTUGs`,
	"modalBody": `RecipeModal_modalBody__H5zfN`,
	"modalFooter": `RecipeModal_modalFooter__cxO7x`,
	"alert": `RecipeModal_alert__Wq53O`,
	"label": `RecipeModal_label__GCQ9U`,
	"tip": `RecipeModal_tip__MJ7Et`,
	"error": `RecipeModal_error__OHAgc`,
	"networkError": `RecipeModal_networkError__pIZH6`,
	"ownerContainer": `RecipeModal_ownerContainer__G1TAo`
};
export default ___CSS_LOADER_EXPORT___;

import { inflect } from 'inflection';
import _ from 'lodash';
import React, { useState, useEffect, useCallback } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import Button from 'components/Button/Button';
import ModulesModal from 'components/ModulesModal';
import NavBar from 'components/NavBar/NavBar';
import RecipeModal from 'pages/Combinations/components/RecipeModal';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  actions,
  selectModulesBundlesForCombinations,
  selectModulesInfo,
  selectModulesSections,
} from 'store/modules/slice';
import { ModulesBundle } from 'store/modules/types';
import { resetRecipe } from 'store/recipeSlice';
import styles from './Combinations.module.scss';
import CombinationNamesModal from './components/CombinationNamesInformModal/CombinationNamesInformModal';
import CombinationPreview from './components/CombinationPreview';
import CombinationsList from './components/CombinationsList';
import CombinationsListToggle from './components/CombinationsListToggle';
import { CombinationItem, useCombinations } from './useCombinations';
import {
  CombinationNameSessionStorageItem,
  RecipeCombination, buildRecipeCombinations,
  getSessionStorageCombinations,
} from './utils';
import { downloadExportedRecipe } from './utils-export';

type LocationType = {
  state: { from: string };
  key: string;
};

const Combinations: React.FC = () => {
  const dispatchToRedux = useAppDispatch();
  const location: LocationType = useLocation();
  const modulePath = location?.state?.from?.replace('?', '') ?? '';
  const info = useAppSelector(selectModulesInfo);
  const sections = useAppSelector(selectModulesSections);
  const bundles = useAppSelector(selectModulesBundlesForCombinations);

  const {
    combinations,
    combinationsNumber,
    combinationsPossibleNumber,
    removeCombinations,
  } = useCombinations(sections, bundles);

  const [activeIndex, setActiveIndex] = useState(NaN);
  const [selected2Indexes, setSelected2Indexes] = useState<number[]>([]);
  const onSelectToggle = useCallback((idx: number): void => setSelected2Indexes((list) => {
    if (list.some(item => item === idx)) {
      return list.filter(item => item !== idx);
    } else {
      return [...list, idx].sort((a, b) => a - b);
    }
  }), []);

  const removeCombinationsHandler = (indexes: number[], showToast = true): void => {
    removeCombinations(indexes, showToast);
    setSelected2Indexes(list => list.filter(idx => !indexes.includes(idx)));
  };

  useEffect(() => setActiveIndex((prev) => {
    const next = combinations.findIndex((item, idx) => item && idx >= prev);

    return next > -1 ? next : combinations.findIndex(i => i);
  }), [combinations]);

  const [isModulesModalOpen, setIsModulesModalOpen] = useState(false);

  const [prevBundlesState, setPrevBundlesState] = useState<ModulesBundle[] | null>(null);

  useEffect(() => {
    if (isModulesModalOpen && bundles) {
      setPrevBundlesState(_.cloneDeep(bundles));
    } else {
      setPrevBundlesState(null);
    }
  }, [isModulesModalOpen]);

  const onModulesModalFormSubmit = (): void => {
    setActiveIndex(0);
    setSelected2Indexes([]);
    setIsModulesModalOpen(false);
  };

  const onModulesModalFormCloseWithoutSave = (): void => {
    dispatchToRedux(actions.updateModulesBundles(prevBundlesState));

    setActiveIndex(0);
    setSelected2Indexes([]);
    setIsModulesModalOpen(false);
  };

  const [callback, setCallback] = useState<{ next:() => void }>({ next:() => {} });
  const [isCombinationNamesInformModalOpen, setIsCombinationNamesInformModalOpen] = useState(false);
  const [isRecipeModalOpen, setIsRecipeModalOpen] = useState(false);
  const [recipeCombinations, setRecipeCombinations] = useState<RecipeCombination[]>([]);
  const recipeModalCloseHandler = (): void => {
    setIsRecipeModalOpen(false);
    dispatchToRedux(resetRecipe());
  };

  useEffect(() => {
    if (isRecipeModalOpen && bundles && sections) {
      const selectedCombinations = selected2Indexes.map(idx => combinations[idx]);
      const list = buildRecipeCombinations(selectedCombinations, bundles, sections);
      setRecipeCombinations(list);
    }
  }, [isRecipeModalOpen, selected2Indexes, bundles, sections]);

  const exportRecipe = (): void => {
    const selectedCombinations = selected2Indexes.map(idx => combinations[idx]);
    const permutations = buildRecipeCombinations(selectedCombinations, bundles || [], sections || []);
    downloadExportedRecipe(permutations).catch(console.error); // eslint-disable-line no-console
  };

  if (!info || !sections || !bundles) {
    return <Navigate to="/" />;
  }

  const combinationsNameCheckHandler = (callbackProp: () => void): void => {
    const sessionData = getSessionStorageCombinations(modulePath);
    const selected = selected2Indexes.map(el => (combinations[el].filter(Boolean)[0] as CombinationItem).moduleIdx);
    const filtered = sessionData.filter(
      (el: CombinationNameSessionStorageItem) => selected.includes(el.index) && el.name,
    );

    if (filtered.length && selected.length !== filtered.length) {
      setIsCombinationNamesInformModalOpen(true);
      setCallback({ next: callbackProp });
    } else {
      callbackProp();
    }
  };

  return (
    <>
      {isCombinationNamesInformModalOpen && (
        <CombinationNamesModal
          callback={callback}
          onModalClose={(): void => {
            setIsCombinationNamesInformModalOpen(false);
          }}
        />
      )}
      {isRecipeModalOpen && (
        <RecipeModal
          permutations={recipeCombinations}
          onModalClose={recipeModalCloseHandler}
          onRecipeSuccess={(): void => removeCombinationsHandler(selected2Indexes, false)}
        />
      )}
      {isModulesModalOpen && (
        <ModulesModal
          jobZeroId={info.jobZero.id}
          moduleBundleId={info.moduleBundle.id}
          onModalClose={onModulesModalFormCloseWithoutSave}
          onFormSubmit={onModulesModalFormSubmit}
          isNoMoreToAdd={combinationsNumber >= combinationsPossibleNumber}
        />
      )}
      <NavBar textContent={info.jobZero.name} />
      <div className={styles.container}>
        <div className={styles.container__left}>

          <div className={styles.container__left__header}>
            <div>
              {`${combinationsNumber} ${inflect('combination', combinationsNumber)} added`}
            </div>
            <div>{combinationsPossibleNumber} possible</div>
          </div>

          <div className={styles.container__left__actions}>
            <div className="flex-grow-1 me-2">
              <Button
                type="button"
                intent="secondary"
                isDisabled={false}
                label="Add Combinations"
                onClick={(): void => setIsModulesModalOpen(true)}
              />
            </div>
            <Button
              type="button"
              intent="secondary"
              isDisabled={selected2Indexes.length === 0}
              label={selected2Indexes.length ? `Export (${selected2Indexes.length})` : 'Export'}
              onClick={(): void => {
                combinationsNameCheckHandler(exportRecipe);
              }}
            />
            <Button
              type="button"
              isDisabled={selected2Indexes.length === 0}
              label={selected2Indexes.length ? `Build (${selected2Indexes.length})` : 'Build'}
              onClick={(): void => {
                combinationsNameCheckHandler(() => setIsRecipeModalOpen(true));
              }}
            />
          </div>

          <div className={styles.container__left__counters}>
            <CombinationsListToggle
              combinations={combinations}
              selectedIndexes={selected2Indexes}
              onChangeSelection={setSelected2Indexes}
            />

            {selected2Indexes.length > 0 && (
              <button
                type="button"
                onClick={(): void => removeCombinationsHandler(selected2Indexes)}
                className='btn btn-outline-danger'
              >
                {`Remove Selected (${selected2Indexes.length})`}
              </button>
            )}
          </div>

          <CombinationsList
            className={`${styles.container__left__combinations} flex-grow-1`}
            combinations={combinations}
            sections={sections}
            selectedIndexes={selected2Indexes}
            activeIndex={activeIndex}
            onClick={setActiveIndex}
            onToggle={onSelectToggle}
            locationPath={modulePath}
          />
        </div>

        <CombinationPreview
          sections={sections}
          bundles={bundles}
          combination={combinations[activeIndex]}
          index={activeIndex}
          onRemove={(): void => removeCombinationsHandler([activeIndex])}
        />
      </div>
    </>
  );
};

export default Combinations;

import classNames from 'classnames';
import React, { Fragment, memo, useState } from 'react';
import BiLayersFill from 'assets/images/bi-layers-fill.svg';
import AccessibleDiv from 'components/AccessibleDiv/AccessibleDiv';
import { ModulesSection } from 'store/modules/types';
import styles from '../Combinations.module.scss';
import { CombinationItem, CombinationSparseArray, isCombinationItemWithGroup } from '../useCombinations';
import { getSessionStorageCombinationName } from '../utils';
import CombinationName from './CombinationName/CombinationName';

type Props = {
  sectionsToView: (readonly [ModulesSection, number])[];
  combination: CombinationSparseArray;
  index: number;
  active: boolean;
  selected: boolean;
  onClick: (idx: number) => void;
  onToggle: (idx: number) => void;
  locationPath: string;
};

const CombinationsListItem: React.FC<Props> = (props) => {
  const [showNameEditor, setShowNameEditor] = useState(false);
  const [showValidationError, setShowValidationError] = useState(false);

  const {
    sectionsToView,
    combination,
    index,
    active,
    selected,
    onClick,
    onToggle,
    locationPath,
  } = props;

  const combinationName = getSessionStorageCombinationName(
    locationPath,
    combination.filter(Boolean) as CombinationItem[],
  );

  const activeClass = active ? styles.container__left__combinations__combination_active : '';
  const handler = (): void => onClick(index);

  return (
    <AccessibleDiv
      onClick={handler}
      onKeyUp={handler}
      className={`${activeClass} ${styles.container__left__combinations__combination}`}
    >
      <div className={styles.container__left__combinations__combination__header__wrapper} >
        <div className={styles.container__left__combinations__combination__header}>
          <div className={classNames('d-flex', styles.container__left__combinations__combination__name,
            { [styles.container__combinationWrapper]: showNameEditor })}>
            <input
              className="form-check-input"
              type="checkbox"
              onChange={(): void => onToggle(index)}
              checked={selected}
            />
            {!showNameEditor && !(combinationName && selected) && `#${index + 1}`}
            {!showNameEditor && combinationName && selected && (<span>{combinationName}</span>)}
            {selected && (
              <CombinationName
                setShowValidationError={setShowValidationError}
                showNameEditor={showNameEditor}
                setShowNameEditor={setShowNameEditor}
                showValidationError={showValidationError}
                combinationName={combinationName}
                index={(combination.filter(Boolean)[0] as CombinationItem)?.moduleIdx}
                locationPath={locationPath}
              />
            )}
          </div>
          {!showNameEditor && (<span>Asset #</span>)}
        </div>
        {showValidationError && (
          <p className={styles.container__left__combinations__combination__header__inputErrorMsg}>
            {'Name can\'t include special characters, only letters and numbers'}
          </p>
        )}
      </div>
      <div className={ styles.container__left__combinations__combination__items }>
        {sectionsToView.map(([section, idx]) => {
          const item = combination[idx];
          let number: string | JSX.Element = 'none';
          if (item) {
            number = isCombinationItemWithGroup(item)
              ? <><BiLayersFill /> {item.modules.map(i => i + 1).join(', ')}</>
              : (item.moduleIdx + 1).toString();
          }

          return (
            <Fragment key={section.section.id}>
              <div className="zone">
                {section.section.displayname}
              </div>
              <div className="number">{number}</div>
            </Fragment>
          );
        })}
      </div>
    </AccessibleDiv>
  );
};

export default memo(CombinationsListItem);

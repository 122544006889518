import React, { ForwardedRef, useEffect, useRef, useState } from 'react';
import EditIcon from 'assets/images/EditIcon/EditIcon';
import Button from 'components/Button/Button';
import { ALL_SYMBOLS_REGEX } from 'const';
import {
  getSelectedTextCollectionData,
  SelectedTextCollectionSessionStore,
  SessionStorageKeys,
} from 'utils/selectedTextCollectionSessionStorage';
import styles from './CombinationName.module.scss';

type CombinationNamePropsType = {
  setShowNameEditor: (value: boolean) => void;
  showNameEditor: boolean;
  setShowValidationError: (value: boolean) => void;
  showValidationError: boolean;
  locationPath: string;
  combinationName: string;
  index: number;
};

const CombinationName: React.FC<CombinationNamePropsType> = ({
  setShowNameEditor,
  showNameEditor,
  setShowValidationError,
  showValidationError,
  locationPath,
  combinationName,
  index,
}) => {
  const inputRef = useRef();
  const [name, setName] = useState(combinationName);

  const editHandler = (e: React.MouseEvent): void => {
    e.preventDefault();
    setShowNameEditor(true);
  };

  const saveHandler = (): void => {
    const regex = new RegExp(ALL_SYMBOLS_REGEX);
    const isValid = !regex.test(name);
    setShowValidationError(!isValid);

    if (!isValid) {
      return;
    }

    const selectedTextCollectionSessionStorageData: SelectedTextCollectionSessionStore[] =
        getSelectedTextCollectionData();

    const updatedSessionData = selectedTextCollectionSessionStorageData
      .reduce((
        updatedTextCollection: SelectedTextCollectionSessionStore[],
        textCollection: SelectedTextCollectionSessionStore,
      ) => {
        if (textCollection.location === locationPath) {
          let combinations = textCollection?.combinations ?? [];
          const indexOfElement = combinations.findIndex(item => item.index === index);
          const value = { index, name };

          if (indexOfElement >= 0) {
            combinations[indexOfElement] = value;
          } else {
            combinations = [...combinations, value];
          }

          return [...updatedTextCollection, { ...textCollection, combinations }];
        }

        return [...updatedTextCollection, textCollection];
      }, []);

    sessionStorage.setItem(SessionStorageKeys.SELECTED_TEXT_COLLECTIONS,
      JSON.stringify(updatedSessionData),
    );

    setShowNameEditor(false);
  };

  useEffect(() => {
    if (inputRef?.current) {
      (inputRef.current as unknown as HTMLInputElement).focus();
    }
  }, [showNameEditor]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setName(e.target.value);
    setShowValidationError(false);
  };

  return (
    <div className={styles.CombinationName__wrapper}>
      {showNameEditor ? (
        <div className={styles.CombinationName__inputWrapper}>
          <input
            className={styles.CombinationName__input}
            ref={inputRef as unknown as ForwardedRef<HTMLInputElement>}
            type="text"
            name=""
            id=""
            value={name ?? ''}
            onBlurCapture={saveHandler}
            maxLength={100}
            onChange={onChange}
          />
          <Button
            type="submit"
            isDisabled={showValidationError}
            label="Save"
            styleOverride={{ padding: '0 12px 2px', fontSize: '12px', marginLeft: '4px' }}
            formId="recipe-form"
            isLoading={false}
            onClick={saveHandler} />
        </div>
      ) : (
        <button className={styles.CombinationName__button} onClick={editHandler}>
          <EditIcon />
        </button>
      )}
    </div>
  );
};

export default CombinationName;

import React from 'react';
import ReactDOM from 'react-dom';
import InformationIcon from 'assets/images/InformationIcon';
import AccessibleDiv from 'components/AccessibleDiv/AccessibleDiv';
import Button from 'components/Button/Button';
import styles from './CombinationNamesInformModal.module.scss';

type BackdropProps = { onModalClose : () => void };
type CallbackNext = { next: () => void };
type CombinationNamesModalProps = BackdropProps & {
  callback: CallbackNext;
};

const Backdrop: React.FC<BackdropProps> = ({ onModalClose }:BackdropProps) =>
  <AccessibleDiv className={'custom-backdrop'} onClick={onModalClose} onKeyUp={(): void => undefined}/>;

const ModalOverlay: React.FC<CombinationNamesModalProps> = ({
  onModalClose,
  callback: { next },
}) => {
  return (
    <div className={`custom-modal ${styles.CombinationNamesInformModal}`}>
      <div className={'modal-body pt-3 px-4'}>
        <header className={styles.CombinationNamesInformModal__header}>
          <h6 className={styles.CombinationNamesInformModal__header_title}>
            <span className={`me-2 ${styles.CombinationNamesInformModal__header_icon}`}>
              <InformationIcon />
            </span>
            <span>Not all selected combinations have names</span>
          </h6>
        </header>
        <p className={styles.CombinationNamesInformModal__message}>
            Are you sure you want to continue?<br />
            Go back to the previous step if you want to check the name of the combinations.
        </p>
      </div>
      <div className={'pt-0 pb-4 px-4'}>
        <div className="d-flex justify-content-end">
          <button
            className="me-3 btn btn-outline-primary custom-button"
            onClick={onModalClose}
            type="button"
          >
            <span>Go back</span>
          </button>

          <Button
            label="Continue"
            type="button"
            onClick={(): void => {
              onModalClose();
              next();
            }}
          />
        </div>
      </div>
    </div>
  );
};

const CombinationNamesModal: React.FC<CombinationNamesModalProps> = ({
  onModalClose,
  callback,
}) => {
  return (
    <>
      {ReactDOM.createPortal(
        <Backdrop onModalClose={onModalClose} />,
        document.getElementById('backdrop-root') as HTMLDivElement,
      )}
      {ReactDOM.createPortal(
        <ModalOverlay
          callback={callback}
          onModalClose={onModalClose}
        />,
        document.getElementById('modal-root') as HTMLDivElement,
      )}
    </>
  );
};

export default CombinationNamesModal;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EditIcon_EditIconWrapper__YVUJy {
  display: inline-block;
  position: relative;
  top: -2px;
}`, "",{"version":3,"sources":["webpack://./src/assets/images/EditIcon/EditIcon.module.scss"],"names":[],"mappings":"AACA;EACE,qBAAA;EACA,kBAAA;EACA,SAAA;AAAF","sourcesContent":["@use \"/home/runner/_work/gmc-dc-pbui-fe/gmc-dc-pbui-fe/src/styles/global-variables.scss\" as *;\n.EditIconWrapper {\n  display: inline-block;\n  position: relative;\n  top: -2px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"EditIconWrapper": `EditIcon_EditIconWrapper__YVUJy`
};
export default ___CSS_LOADER_EXPORT___;

import React from 'react';
import styles from './EditIcon.module.scss';

const EditIcon: React.FC = () => {
  return (
    <div className={styles.EditIconWrapper}>
      <svg width="12" height="14" viewBox="0 0 8 9" fill="none">
        <path d="M5.66667 0.338333L6.91667 1.58833L5.96375 2.54167L4.71375 1.29167L5.66667
      0.338333ZM0.666668 5.33333V6.58333H1.91667L5.37458 3.13042L4.12458 1.88042L0.666668
      5.33333ZM0.666668 7.83333H7.33333V8.66667H0.666668V7.83333Z" fill="#303240"/>
      </svg>
    </div>
  );
};

export default EditIcon;

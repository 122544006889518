import React, { useMemo } from 'react';
import { ModulesSection } from 'store/modules/types';
import { CombinationSparseArray } from '../useCombinations';
import { combinationCreateKey } from '../utils';
import CombinationsListItem from './CombinationsListItem';

type Props = {
  sections: ModulesSection[];
  combinations: CombinationSparseArray[];
  activeIndex: number;
  selectedIndexes: number[];
  onClick: (idx: number) => void;
  onToggle: (idx: number) => void;
  className?: string;
  locationPath: string;
};

const CombinationsList: React.FC<Props> = (props) => {
  const {
    sections,
    combinations,
    activeIndex,
    selectedIndexes,
    onClick,
    onToggle,
    className,
    locationPath,
  } = props;

  const sectionsToView = useMemo(
    () => sections.map((item, idx) => [item, idx] as const).filter(
      ([item]) => item.placeholder && item.bundleIndexes?.length > 0,
    ),
    [sections],
  );

  const selectedMap = useMemo(
    () => selectedIndexes.reduce((acc, item) => {
      acc[item] = true;

      return acc;
    }, new Array(combinations.length) as boolean[]),
    [selectedIndexes], // combinations are intentionally skipped from the deps
  );

  return (
    <div className={className}>
      {combinations.map((combination, index) => combination
        ? <CombinationsListItem
          key={combinationCreateKey(combination)}
          sectionsToView={sectionsToView}
          combination={combination}
          index={index}
          active={activeIndex === index}
          selected={!!selectedMap[index]}
          onClick={onClick}
          onToggle={onToggle}
          locationPath={locationPath}
        />
        : null,
      )}
    </div>
  );
};

export default CombinationsList;

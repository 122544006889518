// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CombinationName_CombinationName__wrapper__CoUbf {
  display: flex;
  flex: 1 0 auto;
}
.CombinationName_CombinationName__button__6xcS6 {
  padding: 0 6px;
  border: 0;
  background: transparent;
}
.CombinationName_CombinationName__input__XbWgy {
  background: transparent;
  border: 0;
  flex: 1 0 auto;
  margin: 0;
  padding: 0;
}
.CombinationName_CombinationName__inputWrapper__xz2pF {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.CombinationName_CombinationName__input__XbWgy:focus {
  border: 0;
  outline: 0;
}`, "",{"version":3,"sources":["webpack://./src/pages/Combinations/components/CombinationName/CombinationName.module.scss"],"names":[],"mappings":"AAEI;EACI,aAAA;EACA,cAAA;AADR;AAII;EACI,cAAA;EACA,SAAA;EACA,uBAAA;AAFR;AAKI;EACI,uBAAA;EACA,SAAA;EACA,cAAA;EACA,SAAA;EACA,UAAA;AAHR;AAKQ;EACI,aAAA;EACA,8BAAA;EACA,WAAA;AAHZ;AAMQ;EACI,SAAA;EACA,UAAA;AAJZ","sourcesContent":["@use \"/home/runner/_work/gmc-dc-pbui-fe/gmc-dc-pbui-fe/src/styles/global-variables.scss\" as *;\n.CombinationName {\n    &__wrapper {\n        display: flex;\n        flex: 1 0 auto;\n    }\n\n    &__button {\n        padding: 0 6px;\n        border: 0;\n        background: transparent;\n    }\n\n    &__input {\n        background: transparent;\n        border: 0;\n        flex: 1 0 auto;\n        margin: 0;\n        padding: 0;\n\n        &Wrapper {\n            display: flex ;\n            justify-content: space-between;\n            width: 100%;\n        }\n\n        &:focus {\n            border: 0;\n            outline: 0;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"CombinationName__wrapper": `CombinationName_CombinationName__wrapper__CoUbf`,
	"CombinationName__button": `CombinationName_CombinationName__button__6xcS6`,
	"CombinationName__input": `CombinationName_CombinationName__input__XbWgy`,
	"CombinationName__inputWrapper": `CombinationName_CombinationName__inputWrapper__xz2pF`
};
export default ___CSS_LOADER_EXPORT___;
